import { useState, useEffect } from 'react'
import { Typography, Grid, IconButton, Stack } from '@mui/material'
import PropTypes, { string } from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import KeyIcon from '@mui/icons-material/Key'
import PersonIcon from '@mui/icons-material/Person'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import useData from '../../hooks/data/useGridData'
import ErrorFetching from '../errors/ErrorFetching'
import ProgressLoading from '../../components/ui/listprogress/ProgressLoading'
import ListView from '../../components/ui/listview/AQHListView'
import AQHButton from '../../components/ui/AQHButton'
import { gridListTypes } from '../../configs/gridListSettings'
import Constants from '../../utilities/Constants'
import apiURLs from '../../utilities/apiURLs'
import useModal from '../../hooks/useModal'
import { useListProjects } from '../../hooks/data/useListData'
import EditUserPasswordModal from './EditUserPasswordModal'
import Modal from '../../components/ui/modal/Modal'
import CreateFieldTeam from './CreateFieldTeam'




function FieldTeamProject( ) {
  const navigate = useNavigate()
  const { data: listProjects, isLoading: projectsIsLoading } = useListProjects()
  const [id, setId] = useState(0)
  const notify = (toastMessage) => toast.error(toastMessage)

  useEffect(() => {
    if (projectsIsLoading === false) {
      if (listProjects?.data?.status !== 'success') {
        notify(listProjects?.data?.message || 'Error loading projects !')
      } else {
        setId(listProjects?.data?.data?.projects[0]?.projectId ?? 0)
      }
    }
  }, [projectsIsLoading])

  // edit password modal
  const { isShowing, toggle } = useModal()
  const [editPasswordData, setEditPasswordData] = useState({})
  const editPasswordHandler = (data) => {
    setEditPasswordData(data)
    toggle()
  }

   // form modal
   const { isShowing: formModalIsShowing, toggle: toggleFormModal } = useModal()
   const [editId, setEditId] = useState(null)
 
   const closeFormModal =()=>{
     toggleFormModal()
     setTimeout(()=>{
       setEditId(null)
     },600)
   }
  // .........

  const {
    isLoading,
    data: fieldTeamData,
    isError,
    error,
    isFetching,
    refetch
  } = useData(`${apiURLs.listGridFieldTeam}${id}`)
  if (isLoading) {
    return <ProgressLoading fetched={isFetching} />
  }
  if (isError) {
    return <ErrorFetching error={error} />
  }

  // console.log(fieldTeamData)
  return (
    <Grid container bgcolor="bg.main">
      <Grid item xs={12}>
        <Stack direction="row" p={1.5} justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Field Team</Typography>
          <AQHButton onClick={() => toggleFormModal()}>Create</AQHButton>
        </Stack>
      </Grid>
      <Grid item xs={12} px={3}>
        <ListView
          id={gridListTypes.fieldTeam}
          style={{ height: 550 }}
          columns={[
            { name: 'id', header: 'Id', defaultVisible: false, type: 'number', defaultWidth: 80 },
            { name: 'firstName', defaultFlex: 1, header: 'First Name', minWidth: 120 },
            { name: 'lastName', defaultFlex: 1, header: 'Last Name', minWidth: 200 },
            { name: 'mobile', defaultFlex: 1, header: 'Mobile', minWidth: 190 },
            { name: 'userName', defaultFlex: 1, header: 'User Name', minWidth: 100 },
            {
              id: 'editFieldTeam',
              defaultFlex: 0.5,
              minWidth: 60,
              textAlign: 'center',
              sortable: false,
              render: ({ data }) => (
                <>
                  <IconButton
                    onClick={() => {
                      setEditId(+data.id)
                      toggleFormModal()
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() =>
                      editPasswordHandler({
                        userId: data.userId,
                        employeeName: `${data.firstName} ${data.lastName}`,
                      })
                    }
                  >
                    <KeyIcon />
                  </IconButton>
                </>
              ),
            },
          ]}
          data={fieldTeamData}
          defaultFilters={[
            { name: 'firstName', operator: 'contains', type: 'string' },
            { name: 'lastName', operator: 'contains', type: 'string' },
            { name: 'mobile', operator: 'contains', type: 'string' },
            { name: 'userName', operator: 'contains', type: 'string' },
          ]}
        />
      </Grid>
      <Modal
        isOpen={isShowing}
        handleClose={toggle}
        title={editPasswordData.employeeName ?? ''}
        subtitle="Change Password"
        closeButtonText="null"
        icon={<PersonIcon />}
      >
        <EditUserPasswordModal userData={editPasswordData} toggle={toggle} />
      </Modal>
      <Modal
        isOpen={formModalIsShowing}
        handleClose={closeFormModal}
        title={`${editId?'Edit':'Add'} Field Team User`}
        subtitle="null"
        closeButtonText="null"
        icon={<PersonIcon />}
        className='form-modal'
      >
        <CreateFieldTeam toggle={closeFormModal} editId={+editId } refetch={refetch} />
      </Modal>
      
    </Grid>
  )
}



export default FieldTeamProject
