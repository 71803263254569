import moment from 'moment'
import * as yup from 'yup'
import { Roles } from '../auth'

// ..........................................................general Settings........................................................................

// used in create attendance(edit mode),create store(edit mode)
export const formGeneralSettings = {
  viewOnMapURL: 'https://www.google.co.in/maps/search/',
  viewOnMapFallBackURL: 'https://www.google.co.in/maps/place/India',
  // eslint-disable-next-line react/jsx-filename-extension
  settingsConfirmation: (
    <>
      Confirm that you want to save the data. <br />
      Once saved, it cannot be edited / deleted
    </>
  ),
}
// ..........................................................Project Form Settings........................................................................

const questionTypes = {
  Alphanumeric: {id:'Alphanumeric',label:'Alphanumeric'},
  Numeric: {id:'Numeric',label:'Numeric'},
  PhoneNumber: {id:'PhoneNumber',label:'Phone Number'},
  Email: {id:'Email',label:'Email'},
  Pincode: {id:'Pincode',label:'Pincode'},
  IntegerPositive: {id:'IntegerPositive',label:'Number'},
  Image: {id:'Image',label:'Image'},
}

const questionTypesOptions = Object.keys(questionTypes).map((e)=>({
  label:questionTypes[e].label,
  inputSubType:questionTypes[e].id
}))


const formTypeConfigs = {
  [questionTypes.Alphanumeric.id]: {
    inputType: 'Text',
    minLength: 0,
    maxLength: 200,
    minValue: null,
    maxValue: null,
    associatedData: null,
  },
  [questionTypes.Numeric.id]: {
    inputType: 'Text',
    minLength: 0,
    maxLength: 200,
    minValue: null,
    maxValue: null,
    associatedData: null,
  },
  [questionTypes.PhoneNumber.id]: {
    inputType: 'Text',
    minLength: +process.env.REACT_APP_PHONE_LENGTH || 0,
    maxLength: +process.env.REACT_APP_PHONE_LENGTH || 0,
    minValue: null,
    maxValue: null,
    associatedData: null,
  },
  [questionTypes.Email.id]: {
    inputType: 'Text',
    minLength: 1,
    maxLength: 75,
    minValue: null,
    maxValue: null,
    associatedData: null,
  },
  [questionTypes.Pincode.id]: {
    inputType: 'Text',
    minLength: +process.env.REACT_APP_PIN_CODE_LENGTH || 0,
    maxLength: +process.env.REACT_APP_PIN_CODE_LENGTH || 0,
    minValue: null,
    maxValue: null,
    associatedData: null,
  },
  [questionTypes.IntegerPositive.id]: {
    inputType: 'Number',
    minLength: 1,
    maxLength: 8,
    minValue: 0,
    maxValue: 99999999,
    associatedData: null,
  },
  [questionTypes.Image.id]: {
    inputType: 'Image',
    minLength: null,
    maxLength: null,
    minValue: null,
    maxValue: null,
    associatedData: null,
  },
}
const formatLabel = (q) => q.split(' ').join('')

export const projectFormSettings = {
  // used in createFormModal and form under projects
  questionTypes,
  questionTypesOptions,
  formTypeConfigs,
  formatLabel
}

// .............prev
// ..........................................................Store Settings........................................................................

// ..........................................................Store Target Settings........................................................................

export const storeTargetSettings = {
  // used in Target Duration
  targetDurationLimit: '2022-10-01',
}

// ..........................................................Inventory settings........................................................................

export const inventorySettings = {
  // used in Types in InventoryCreateModal, in Primary Heading in CreateInventory and CreateInventoryAddBefore Office
  inventoryTransactionTypes: ['Opening / Closing', 'Add'],
  // 'Add Before Office'
}

// ..........................................................Schedule..................................................................................

// used in attendance status options

const attendanceStatusTypes = {
  Approved: 2,
  Cancelled_On_Request: 7,
  Denied: 3,
  Not_Applicable: 0,
  Request: 1,
  Request_Denied: 6,
  Request_For_Cancellation: 5,
  Requested_Again: 4,
}

// status options
const attendanceStatuses = Object.keys(attendanceStatusTypes).map((e) => ({
  label: e.split('_').join(' '),
  value: attendanceStatusTypes[e],
}))

// allowed changes in status
const allowedStatusChanges = {
  Approved: [attendanceStatusTypes.Denied],
  Denied: [attendanceStatusTypes.Approved],
  Request_For_Cancellation: [
    attendanceStatusTypes.Cancelled_On_Request,
    attendanceStatusTypes.Denied,
  ],
  Requested_Again: [attendanceStatusTypes.Approved, attendanceStatusTypes.Denied],
  Request: [attendanceStatusTypes.Approved, attendanceStatusTypes.Denied],
}

export const scheduleSettings = {
  // If type meeting / training / other off -> user can change the type and sub type
  changeableAttendanceTypes: [7, 8, 9],
  // If type leave, weekly off, special occasion then attendance type cannot be changed.  Status can be changed
  changeableStatuses: [11, 6, 15],
  searchableAttendanceType: [6, 7, 8, 9, 11, 15],
  attendanceStatuses,
  allowedStatusChanges,
}

// ..........................................................Report Settings........................................................................

// validation types
const validationTypes = {
  text: 'string',
  number: 'number',
  date: 'date',
  singleComboBox: 'array',
  multiComboBox: 'object',
  checkBox: 'bool',
}

// report actions based on report result type
const reportAction = {
  html: 'html',
  excel: 'excel',
}

const optionsTypes = {
  cities: 'city',
  states: 'state',
  accounts: 'accounts',
  stores: 'stores',
  attendanceDateRange: 'attendanceDateRange',
}

// parameters custom validations
const formDateValidationStock = yup
  .date()
  .transform((v) => (moment(v).isValid() ? v : null))
  .nullable()
  .required('Form Date is required')
  .max(yup.ref('toDateStock'), 'Form Date can not be greater than To Date')
  .test('max31daysSelection', 'Date range should be in  between 31 days', function validation(v) {
    // eslint-disable-next-line react/no-this-in-sfc
    const v2 = this.options.parent.toDateStock
    return moment(v2).subtract(32, 'days').isBefore(moment(v))
  })

// default values of report form parameters
const defaultValues = {
  reportType: '',
  city: '',
  state: '',
  attendanceDateRange: null,
  // test: '',
  // name: '',
  // testCheckBox: false,
  // toDate: null,
  // fromDate: null,
  // code1: 0,
}

// ..................notes.......................................
// in parameters labelId and labelName is mandatory if validation type is single or multi combo box, for autocomplete
// default values ('',null, or value ) are required for combo boxes as mui autocomplete requires else empty array are passed in autocomplete
// default value for checkbox is required as per AQHSelectiveInput else empty array will be passed in checked prop
//

// reportsSetting
export const reportsSettings = {
  validationTypes,
  reportAction,
  optionsTypes,
  defaultValues,
  reportConfigs: {
    storePromoterList: {
      name: 'Store Promoter List',
      roles: [Roles.sysconadmin, Roles.clientadmin],
      description: 'List of promoters and supervisors assigned to stores',
      parameters: [
        {
          name: 'city',
          required: false,
          validationType: validationTypes.multiComboBox,
          labelID: 'cityId',
          labelName: 'cityName',
          label: 'City',
        },
      ],
      reportType: reportAction.html,
    },
    stockDetails: {
      name: 'Stock Details',
      roles: [Roles.sysconadmin, Roles.clientadmin],
      description: 'Daily stock details store wise',
      parameters: [
        {
          name: 'fromDateStock',
          required: true,
          validationType: validationTypes.date,
          label: 'From Date',
          customValidation: formDateValidationStock,
        },
        {
          name: 'toDateStock',
          required: true,
          validationType: validationTypes.date,
          label: 'To Date',
        },
        {
          name: 'accounts',
          required: true,
          validationType: validationTypes.multiComboBox,
          labelID: 'storeId',
          labelName: 'outletName',
          label: 'Account Stores',
        },
        {
          name: 'city',
          required: false,
          validationType: validationTypes.multiComboBox,
          labelID: 'cityId',
          labelName: 'cityName',
          label: 'City',
        },
        {
          name: 'stores',
          required: true,
          validationType: validationTypes.multiComboBox,
          labelID: 'storeId',
          labelName: 'outlate',
          label: 'Stores',
        },
      ],
      reportType: reportAction.html,
    },
    attendanceReport: {
      name: 'Attendance Report',
      roles: [Roles.sysconadmin, Roles.clientadmin],
      description: 'Promoter attendance report for a specific month',
      parameters: [
        {
          name: 'attendanceDateRange',
          required: true,
          validationType: validationTypes.singleComboBox,
          labelID: 'id',
          labelName: 'label',
          label: 'Month',
        },
      ],
      reportType: reportAction.html,
    },
  },
}
