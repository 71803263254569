import { useQuery } from 'react-query'
import moment from 'moment'
import { settings } from '../../configs/settingsConfigs'
import { request } from '../../utilities/axiosUtils'
import apiURLs from '../../utilities/apiURLs'
import { gridListTypes } from '../../configs/gridListSettings'

const useData = (dataSource) => {
  let pathString = ''

  if (dataSource === gridListTypes.projects) {
    pathString = apiURLs.listProjects
  } else if (dataSource.startsWith(gridListTypes.clientUsers)) {
    pathString = dataSource
  } else if (dataSource.startsWith(gridListTypes.fieldTeam)) {
    pathString = dataSource
  } else if (dataSource === gridListTypes.stores) {
    pathString = apiURLs.listGridStore
  } else if (dataSource === gridListTypes.backTeam) {
    pathString = apiURLs.listGridBackteam
  } else if (dataSource.startsWith(gridListTypes.formData)) {
    pathString = dataSource
  }

  return useQuery(
    `${pathString}`,
    () =>
      request({
        url: `${settings.apiUrl}${pathString}`,
        method: apiURLs.getApiMethod,
        requiredToken: true,
      }),
    {
      enabled: !pathString.startsWith(gridListTypes.formData) ,
      cacheTime: 0,
      retry: 2,
      select: (data) => {
        let finalData
        if (dataSource === gridListTypes.projects) {
          finalData = data.data.data.projects?.map((e) => {
            e.id = e.projectId
            e.startDate = moment(e.startDate).format('MM/DD/YYYY')
            e.endDate = moment(e.endDate).format('MM/DD/YYYY')
            return e
          })
        } else if (dataSource.startsWith(gridListTypes.clientUsers)) {
          finalData = data.data.data.backTeams?.map((e) => {
            e.id = e.userId
            return e
          })
        } else if (dataSource.startsWith(gridListTypes.fieldTeam)) {
          finalData = data.data.data.fieldTeam?.map((e) => {
            e.id = e.userId
            return e
          })
        } else if (dataSource.startsWith(gridListTypes.formData)) {
          finalData = data.data.data.formData?.map((e) => {
            e.id = e.formDataId
            e.latitude = (+e.latitude).toFixed(6)
            e.longitude = (+e.longitude).toFixed(6)
            return e
          })
        }
        // .............prev
        else if (dataSource === gridListTypes.backTeam) {
          finalData = data.data.data.backTeams?.map((e) => {
            e.id = e.userId

            return e
          })
        } else if (dataSource === gridListTypes.stores) {
          finalData = data.data.data.store?.map((e) => {
            e.id = e.storeId
            return e
          })
        }

        return finalData
      },
    },
  )
}

export default useData
