import IconDashboard from '@mui/icons-material/Dashboard'
import IconQuestionAnswer from '@mui/icons-material/QuestionAnswer'
import IconPeople from '@mui/icons-material/People'
import IconStore from '@mui/icons-material/Store'
import BiotechIcon from '@mui/icons-material/Biotech';

import { Roles } from '../auth'

// example -  modify as is required

// eslint-disable-next-line import/prefer-default-export
export const menuItems = [
  {
    id: 'projects',
    name: 'Projects',
    auth:  [Roles.sysconadmin, Roles.organizationadmin ], 
    link: '/app/projects',
  },
  {
    id: 'data',
    name: 'Data',
    auth:  [Roles.sysconadmin, Roles.organizationadmin, Roles.projectmanagement],
    link: '/app/data',
  },
  {
    id: 'fieldteam',
    name: 'Field Team',
    auth: [ Roles.projectmanagement],
    link: '/app/project/fieldteam',
  },
]
