import { Button, Stack, Box, IconButton, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DeleteIcon from '@mui/icons-material/Delete'
// import useSwal from '../../../hooks/useSwal'
// import Trash from '../../../assets/icons/trash.png'
import DragNDrop from '../../../assets/icons/dragNDrop.png'
import DropHere from '../../../assets/icons/dropHere.png'
import useModal from '../../../hooks/useModal'
import ImageModal from '../modal/ImageModal'

function ImageUpload(props) {
  const { state, setState, editable, downloadable, setDownloadable } = props
  const fileUpload = useRef()
  // const downloadRef = useRef()
  const dropRef = useRef()
  const { previewImage, remoteImageFile } = state
  const notify = (toastMessage) => toast.error(toastMessage)
  // const { triggerModal, closeModal } = useSwal()
  const validExtensions = ['png', 'jpeg', 'jpg']
  const { isShowing, toggle } = useModal()
  const [dragging, setDragging] = useState(false)
  // const [draggingIndicator, setDraggingIndicator] = useState(false)

  // useEffect(()=>{
  //   if(downloadable){
  //     downloadRef.current.setAttribute('download','abc')
  //   }
  // },[downloadable])

  const selectFile = (selectedFile) => {
    // console.log('test')
    setDownloadable(false)
    const onError = (text) => {
      notify(text)
    }
    // const onSelect = (file) => {
    //   // console.log('123')
    // }
    const maxSizeMB = 400
    // const { onError, onSelect, maxSizeMB } = props;
    // const selectedFile = event.target.files[0]
    const fileExtension = selectedFile.type.split('/')[1]

    if (selectedFile.size > maxSizeMB * 1024) {
      fileUpload.current.value = null
      onError(`File size is more than ${maxSizeMB}kb`)
    } else if (validExtensions.includes(fileExtension) === false) {
      fileUpload.current.value = null
      onError('Image type should be jpg, png or jpeg')
    } else {
      setState({
        ...state,
        currentFile: selectedFile,
        previewImage: URL.createObjectURL(selectedFile),
      })
    }
  }

  const onClickDelete = () => {
    setState({ ...state, currentFile: undefined, previewImage: undefined })
    fileUpload.current.value = null
  }

  const showImage = () => toggle()

  const [draggingTimeOut0, setDraggingTimeOut0] = useState(1)

  // const handleDragLeave = (e) => {
  //   // e.preventDefault()
  //   // e.stopPropagation()
  // }

  const handleDragEnter = () => {
    // console.log('dragEnter')
    setDragging(true)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    // event.stopPropagation()

    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'link'

    if (draggingTimeOut0) {
      window.clearTimeout(draggingTimeOut0)
      setDraggingTimeOut0(() => null)
    }
    setDragging(true)
    setDraggingTimeOut0(
      window.setTimeout(() => {
        setDragging(false)
      }, 200),
    )
  }

  const handleDrop = (event) => {
    event.preventDefault()
    // event.stopPropagation()
    // setDragging(false)
    // console.log('dropped', event)
    // console.log('dropped', event.dataTransfer.files)
    selectFile(event.dataTransfer.files[0])
  }
  return (
    // <Stack onDragOver={handleDragOver} p={1}>
    <Stack width="175px">
      <Box
        sx={{
          width: 175,
          height: 225,
          transition: 'all 1s',
          borderColor: dragging ? 'primary.main' : '#000000',
          bgcolor: dragging ? '#e0ebee' : 'inherit',
          borderWidth: dragging ? 4 : 3,
          borderRadius: '5px',
          borderStyle: 'dashed',
          alignItems: 'center',
          flexDirection: 'column',
          display: 'flex',
          overflow: 'hidden',
        }}
        ref={dropRef}
        // onDragLeave={handleDragLeave}
        // onDragStart={handleDragStart}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Box
          style={{
            width: 175,
            height: 225,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            position: 'relative',
          }}
        >
          {previewImage === undefined ? (
            <Stack spacing={1.5} justifyContent="center" alignItems="center">
              {dragging ? (
                <>
                  <Box component="img" src={DropHere} sx={{ color: 'green' }} width={75} />
                  <Typography>Drop Here</Typography>
                </>
              ) : (
                <>
                  <Box component="img" src={DragNDrop} width={75} />
                  <Box>
                    <Typography textAlign="center">Drag and Drop </Typography>
                    <Typography textAlign="center">Or Select Files</Typography>
                  </Box>
                </>
              )}
            </Stack>
          ) : null}
          {previewImage !== undefined && (
            <IconButton onClick={() => showImage()}>
              <img
                src={previewImage}
                style={{ width: 175, height: 'auto', objectFit: 'cover' }}
                alt=""
                loading="lazy"
              />
            </IconButton>
          )}
          {previewImage !== undefined && (
            <Box
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 10,
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
              }}
            >
              {editable ? (
                <IconButton
                  style={{ color: '#ff3f5b', backdropFilter: 'brightness(1.45)' }}
                  onClick={onClickDelete}
                >
                  <DeleteIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              ) : null}

              {downloadable ? (
                <IconButton
                  sx={{
                    // color:'secondary.main',
                    color: '#ff3f5b',
                    // padding: '5px',
                    backdropFilter: 'brightness(1.45)',
                  }}
                  //  ref={downloadRef}
                  LinkComponent="a"
                  //  href={`/${imageFile.split('/').slice(-1)[0]}`}
                  href={`${remoteImageFile}`}
                  download="a.jpg"
                  target="_blank"
                >
                  <FileDownloadIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              ) : null}
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <input
            // name="profileImage2"
            accept="image/jpg,image/jpeg,image/png"
            ref={fileUpload}
            size="small"
            id="upload-input"
            // control={control}
            type="file"
            // errors={errors}
            label=""
            autoComplete=""
            onChange={(e) => {
              // console.log(e.target.files[0])
              selectFile(e.target.files[0])
            }}
          />
        </Box>
      </Box>
      {editable ? (
        <Box display="flex" justifyContent="center">
          <Button variant="contained" onClick={() => fileUpload.current.click()}>
            Upload
          </Button>
        </Box>
      ) : null}
      <ImageModal isOpen={isShowing} handleClose={toggle}>
        <img
          src={previewImage}
          style={{ display: 'block', width: '100%', height: 'auto' }}
          alt=""
        />
      </ImageModal>
    </Stack>
    // </Stack>
  )
}

ImageUpload.propTypes = {
  state: PropTypes.any.isRequired,
  setState: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  downloadable: PropTypes.bool,
  setDownloadable: PropTypes.func,
}
ImageUpload.defaultProps = {
  editable: true,
  downloadable: false,
  setDownloadable: () => null,
}
export default ImageUpload
