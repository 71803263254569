import { useState, useEffect } from 'react'
import { Typography, Grid, IconButton, Stack, Button } from '@mui/material'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment'
import VisibilityIcon from '@mui/icons-material/Visibility'
// import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'

import useData from '../../hooks/data/useGridData'
import ErrorFetching from '../errors/ErrorFetching'
import ProgressLoading from '../../components/ui/listprogress/ProgressLoading'
import ListView from '../../components/ui/listview/AQHListView'
// import AQHButton from '../../components/ui/AQHButton'
import { gridListTypes } from '../../configs/gridListSettings'
// import Constants from '../../utilities/Constants'
import useModal from '../../hooks/useModal'
import Modal from '../../components/ui/modal/Modal'
import DataModal from './DataModal'
import apiURLs from '../../utilities/apiURLs'
import AQHDropdown from '../../components/ui/AQHDropdown'
import { useListProjects } from '../../hooks/data/useListData'
import ExportPDFModal from './export/ExportPDFModal'

import utils from '../../utilities/Utils'
import { useExportFormDataExcel } from '../../hooks/data/useFormData'



function Data() {
  const { isShowing, toggle } = useModal()
  const [dataProp, setDataProp] = useState(null)
  const [projects, setProjects] = useState([])
  const { data: listProjects, isLoading: projectsIsLoading } = useListProjects()
  const notify = (toastMessage) => toast.error(toastMessage)
  // const notifySuccess = (toastMessage) => toast.success(toastMessage)
  const notifyInfo = (toastMessage) => toast.info(toastMessage)
  const queryClient = useQueryClient();

  const { isShowing: showingExPDF, toggle: toggleExPDF } = useModal()

  const handleClose = () => {
    toggle()
    setDataProp(null)
  }
  const formOptions = {
    mode: 'onBlur',
    defaultValues: {},
    shouldUnregister: false,
  }
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    watch,
    setValue,
  } = useForm(formOptions)


  const watchProject = watch('project')

  useEffect(() => {
    if (projectsIsLoading === false) {
      if (listProjects?.data?.status !== 'success') {
        notify(listProjects?.data?.message || 'Error loading projects !')
      } else {
        setProjects(listProjects?.data?.data?.projects || [])
        setValue('project', listProjects?.data?.data?.projects[0])
      }
    }

  }, [projectsIsLoading])

  const {
    isLoading,
    data: formData,
    isError,
    error,
    isFetching,
  } = useData(`${apiURLs.listFormData}${watchProject?.projectId}`)

  useEffect(() => {
    const loadData = () => {
      if (watchProject?.projectId) {
        queryClient.fetchQuery(`${apiURLs.listFormData}${watchProject?.projectId}`)
      } else {
        queryClient.removeQueries(`${apiURLs.listFormData}${watchProject?.projectId}`)
      }

    }
    loadData()
  }, [watchProject])

  const onExportExcel = async () => {
    notifyInfo('Download initiating.')
    try {
      const response = await useExportFormDataExcel({
        projectId: watchProject.projectId
      })
      if (response?.data?.status === 'success') {
        // setExportingPS(false)
        const fileName = response?.data?.data?.fileName?.split('/').at(-1)
        utils.download(response?.data?.data?.fileURL, fileName)
      }
    } catch (err) {
      // console.log(error)
      notify('Error loading data!')
    }
  }


  if (isLoading) {
    return <ProgressLoading fetched={isFetching} />
  }
  if (isError) {
    return <ErrorFetching error={error} />
  }



  return (
    <Grid container bgcolor="bg.main" sx={{}} spacing={1.5}>
      <Grid item xs={12}>
        <Stack direction="row" p={1.5} justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Data</Typography>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" size="medium" onClick={onExportExcel}>
              Export EXCEL
            </Button>
            <Button variant="outlined" size="medium" onClick={toggleExPDF}>
              Export PDF
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <AQHDropdown
          control={control}
          name="project"
          options={projects}
          getOptionLabel={(option) => option.projectName || ''}
          isOptionEqualToValue={(option, optionValue) =>
            optionValue ? option.projectId === (optionValue?.projectId || optionValue) : false
          }
          label="Project"
          errors={errors}
          disableClearable
        />
      </Grid>
      <Grid item xs={12}>
        <ListView
          id={gridListTypes.projects}
          style={{ height: 550 }}
          columns={[
            {
              id: 'editFieldTeam',
              defaultFlex: 0.25,
              minWidth: 60,
              textAlign: 'center',
              sortable: false,
              render: ({ data }) => (
                <IconButton
                  onClick={() => {
                    setDataProp(data)
                    toggle()
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              ),
            },
            { name: 'id', header: 'Id', defaultVisible: false, type: 'number', defaultWidth: 80 },
            {
              name: 'entryOn',
              defaultFlex: 1,
              header: 'Entry On',
              type: 'customDate',
              filterEditor: DateFilter,
              filterEditorProps: (props, { index }) => ({
                dateFormat: 'DD/MM/YYYY',
                cancelButton: false,
                highlightWeekends: false,
                placeholder: index === 1 ? '' : '',
              }),
              render: ({ value }) =>
                moment(value).isValid ? moment(value).format('DD/MM/YYYY') : '',
              minWidth: 150,
            },
            { name: 'userName', defaultFlex: 1, header: 'Field Team', minWidth: 150 },
            { name: 'latitude', defaultFlex: 1, header: 'Latitude', minWidth: 150 },
            { name: 'longitude', defaultFlex: 1, header: 'Longitude', minWidth: 150 },
            { name: 'organizationName', defaultFlex: 0.75, header: 'Organization', minWidth: 200 },
            { name: 'client', defaultFlex: 1, header: 'Client', minWidth: 150 },
            { name: 'brand', defaultFlex: 1, header: 'Brand', minWidth: 150 },
          ]}
          data={formData ?? []}
          defaultFilters={[
            { name: 'entryOn', operator: 'afterOrOn', type: 'customDate', value: '' },
            { name: 'userName', operator: 'contains', type: 'string' },
            { name: 'latitude', operator: 'contains', type: 'string' },
            { name: 'longitude', operator: 'contains', type: 'string' },
            { name: 'organizationName', operator: 'contains', type: 'string' },
            { name: 'client', operator: 'contains', type: 'string' },
            { name: 'brand', operator: 'contains', type: 'string' },
          ]}
        />
      </Grid>
      <Modal
        isOpen={isShowing}
        handleClose={handleClose}
        title={dataProp?.projectName ?? ''}
        // subtitle={userStoreData.subtitle}
        subtitle=""
        closeButtonText="Close"
        icon={null}
      >
        <DataModal id={dataProp?.formDataId ?? 0} />
      </Modal>
      <Modal
        isOpen={showingExPDF}
        handleClose={toggleExPDF}
        title="Export to PDF"
        // subtitle={userStoreData.subtitle}
        subtitle=""
        closeButtonText='null'
        icon={null}
      >
        <ExportPDFModal toggle={toggleExPDF} projectId={watchProject?.projectId ?? 0} />
      </Modal>
    </Grid>
  )
}

export default Data
