import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import AQHInput from '../../components/ui/AQHInput'
import AQHButton from '../../components/ui/AQHButton'
import { useEditFieldTeamPassword } from '../../hooks/data/useFieldTeamData'

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Minimum 8 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!#%*?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Password does not match'),
})

const defaultValues = {
  password: '',
  confirmPassword: '',
}

const formOptions = {
  mode: 'onBlur',
  defaultValues,
  shouldUnregister: false,
  resolver: yupResolver(validationSchema),
}

function EditUserPasswordModal({ userData, toggle }) {
  const [dataLoading, setDataLoading] = useState(false)
  const { mutateAsync: editFieldTeamPassword } = useEditFieldTeamPassword()
  const notify = (toastMessage) => toast.error(toastMessage)
  const notifySuccess = (toastMessage) => toast.success(toastMessage)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(formOptions)
  const onSubmit = async (data) => {
    setDataLoading(true)
    // const formData = { ...data }
    // console.log(formData)
    const params = {
      userId: userData.userId,
      userPassword: data.password,
    }
    // console.log(params)
    await editFieldTeamPassword(
      { ...params },
      {
        // eslint-disable-next-line no-shadow
        onSuccess: (response) => {
          setDataLoading(false)
          toggle()
          // eslint-disable-next-line no-prototype-builtins
          if (response?.data?.hasOwnProperty('error')) {
            notify(response.data.error.message)
          } else {
            notifySuccess(response.data.message)
            toggle()
          }
        },
        onError: (error) => {
          // console.log(error)
          notify('Something went wrong')

          // console.log(error)
        },
      },
    )
    setDataLoading(false)
  }

  return (
    <Box sx={{ width: { md: '500px', xs: '50vw' } }}>
      <form style={{ flex: 1 }} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid container spacing={1} marginTop={1} justifyContent="center">
          <Grid item xs={12}>
            <AQHInput
              name="password"
              type="password"
              control={control}
              label="Password"
              margin="dense"
              errors={errors}
              maxLength={20}
            />
          </Grid>
          <Grid item xs={12}>
            <AQHInput
              name="confirmPassword"
              type="password"
              control={control}
              label="Confirm Password"
              margin="dense"
              errors={errors}
              maxLength={20}
            />
          </Grid>

          <Grid item xs={12} className="action-buttons" marginTop={1}>
            <AQHButton
              variant="contained"
              size="medium"
              type="submit"
              withLoader
              loading={dataLoading}
              disabled={dataLoading}
            >
              Submit
            </AQHButton>
            <Button variant="outlined" disabled={dataLoading} onClick={() => toggle()}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
EditUserPasswordModal.propTypes = {
  userData: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
}
export default EditUserPasswordModal
