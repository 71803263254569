import { Grid, Stack, Typography, Button, Divider, Box, IconButton } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { ReactSortable } from 'react-sortablejs'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import AQHButton from '../../../components/ui/AQHButton'
import useModal from '../../../hooks/useModal'
import Modal from '../../../components/ui/modal/Modal'
import CreateFormModal from './CreateFormModal'
import AQHTextField from '../../../components/ui/AQHInput'
import { projectFormSettings } from '../../../configs/formSettings'
import {
  useGetFormDefinitionsByID,
  useAddFormDefinitions,
  useEditFormDefinitions,
} from '../../../hooks/data/useFormDefinitions'
import ProgressLoading from '../../../components/ui/listprogress/ProgressLoading'
import { projectState } from '../../../store/projectSlice'
import ModalAlert from '../../../components/ui/modal/ModalAlert'



const { formatLabel, questionTypes } = projectFormSettings
export default function Form() {
  const { isShowing, toggle } = useModal()
  const [formDefinitions, setFormDefinitions] = useState([])
  const { id } = useParams()
  const {
    data: fDefRes,
    isLoading: fDefLoading,
    refetch,
  } = useGetFormDefinitionsByID({ projectId: id })
  const removeQuestion = (label) => setFormDefinitions((s) => s.filter((e) => e.label !== label))
  const notify = (toastMessage) => toast.error(toastMessage)
  const notifySuccess = (toastMessage) => toast.success(toastMessage)
  const [editMode, setEditMode] = useState(false)
  const [addMode, setAddMode] = useState(false)
  const [beingCreated, setBeingCreated] = useState(false)

  const { mutateAsync: addFormDefs } = useAddFormDefinitions()
  const { mutateAsync: editFormDefs } = useEditFormDefinitions()

  const [dataLoading, setDataLoading] = useState(false)
  const [formParams, setFormParams] = useState(null)

  const projectDetails = useSelector(projectState)

  // alert on unassigned
  // const [alertDialogOpen, setAlertDialogOpen] = useState(false)
  // const [alertConfirmed, setAlertConfirmed] = useState(false)
  const SubmitButtonRef = useRef()

  // const confirmAlert = () => {
  //   setAlertConfirmed(true)
  //   setAlertDialogOpen(false)
  //   setTimeout(() => {
  //     SubmitButtonRef.current.firstElementChild.click()
  //   }, 1)
  // }
  // const cancelAlert = () => setAlertDialogOpen(false)

  const validationSchema = yup.object().shape({
    formName: yup.string().required('Form Name is required'),
  })
  const formOptions = {
    mode: 'onBlur',
    defaultValues: { formName: '' },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  }
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm(formOptions)

  const watchFormName = watch('formName')

  useEffect(() => {
    const defaultValues = formDefinitions.reduce(
      (a, e) => ({
        ...a,
        [formatLabel(e.label)]: e?.label,
        [`${formatLabel(e?.label)}_type`]: `${questionTypes[e?.inputSubType]?.label} - ${e?.mandatory ? 'Mandatory' : 'Not Mandatory'
          }`,
      }),
      {},
    )
    Object.keys(defaultValues).forEach((key) => setValue(key, defaultValues[key]))
  }, [formDefinitions])

  useEffect(() => {
    if (fDefLoading === false) {
      if (fDefRes?.data?.status !== 'success') {
        notify(fDefRes?.data?.message || 'Error loading questions !')
      } else {
        const fData = fDefRes?.data?.data?.formDefinition?.formDefinitionDetails
        const tmpParams = fDefRes?.data?.data?.formDefinition

        if (tmpParams.projectId === 0) {
          setAddMode(true)
          setBeingCreated(true)

          return
        }


        delete tmpParams.formDefinitionDetails
        setValue('formName', tmpParams?.formName ?? '')
        setFormParams(tmpParams)

        const tmp = fData?.map((i) => ({
          label: i.label,
          inputSubType: i.inputSubType,
          mandatory: i.mandatory,
          inputType: i.inputType,
          minLength: i.minLength,
          maxLength: i.maxLength,
          minValue: i.minValue,
          maxValue: i.maxValue,
          associatedData: i.associatedData,
        }))
        console.log(tmp)
        setFormDefinitions(tmp || [])
        // setLoadedBit((s) => s | 1) // eslint-disable-line no-bitwise
      }
    }
  }, [fDefRes])

  const saveFormDefinitions = async (data) => {

    // if (!alertConfirmed) {
    //   setAlertDialogOpen(true)
    //   return
    // }

    // console.log(data)
    setDataLoading(true)
    const formDefinitionDetails = formDefinitions.map((item, index) => ({
      ...item,
      sortOrder: index + 1,
    }))

    if (editMode) {
      const params = {
        formDefinitionId: formParams.formDefinitionId,
        formName: watchFormName,
        formGuid: formParams.formGuid,
        organizationID: formParams.organizationId,
        projectID: formParams.projectId,
        formDefinitionDetails,
      }
      await editFormDefs(params, {
        // eslint-disable-next-line no-shadow
        onSuccess: (response) => {
          setDataLoading(false)
          // eslint-disable-next-line no-prototype-builtins
          if (!response) {
            notify('Something went wrong !')
            return
          }
          // eslint-disable-next-line no-prototype-builtins
          if (response.data.hasOwnProperty('error')) {
            notify(response.data.error.message)
          } else {
            notifySuccess(response.data.message)
            setEditMode(false)
            refetch()
          }
        },
        onError: (error) => {
          notify(error.getMessage())
        },
      })
    }
    if (addMode) {
      const params = {
        formName: watchFormName,
        formGuid: "94053706-5d4d-4a04-82a9-cbf5b0ef8354",
        organizationID: projectDetails.organizationId,
        projectID: projectDetails.projectId,
        formDefinitionDetails,
      }
      // console.log(params)
      await addFormDefs(params, {
        // eslint-disable-next-line no-shadow
        onSuccess: (response) => {
          setDataLoading(false)
          // eslint-disable-next-line no-prototype-builtins
          if (!response) {
            notify('Something went wrong !')
            return
          }
          // eslint-disable-next-line no-prototype-builtins
          if (response.data.hasOwnProperty('error')) {
            notify(response.data.error.message)
          } else {
            notifySuccess(response.data.message)
            setAddMode(false)
            refetch()
          }
        },
        onError: (error) => {
          notify(error.getMessage())
        },
      })
    }
  }



  return (
    <>
      <Grid
        container
        bgcolor="bg.main"
        component="form"
        onSubmit={handleSubmit(saveFormDefinitions)}
      >

        <Grid item xs={12}>
          {

            <Stack direction="row" pt={0} pb={0} justifyContent="space-between" alignItems="center">
              {/* <Typography variant="h6">Questions</Typography> */}
              <Box mt={0.5}>
                <AQHTextField
                  name="formName"
                  control={control}
                  label={addMode ? 'Form Name' : 'Form'}
                  margin="normal"
                  maxLength={25}
                  errors={errors}
                  inputProps={{ readOnly:( !editMode && !addMode ) }}
                  disabled={beingCreated}
                />
              </Box>
              <Stack direction='row'>
                {!beingCreated && (editMode || addMode) ? (
                  <AQHButton type="button" onClick={() => toggle()}>
                    Add Question{' '}
                  </AQHButton>
                ) : null}
                {!beingCreated && (editMode || addMode) ? <div ref={SubmitButtonRef}> <AQHButton loading={dataLoading}>Save</AQHButton></div> : null}

                {!editMode && !addMode && ! fDefLoading ? (
                  <AQHButton type="button" onClick={() => setEditMode(true)}>
                    Edit
                  </AQHButton>
                ) : null}

                {beingCreated && addMode ?
                  <AQHButton type="button" onClick={() => setBeingCreated(false)}>Create</AQHButton> : null}


              </Stack>
            </Stack>
          }




          <Divider sx={{ marginTop: 1 }} />
          {fDefLoading ? (
            <Box marginTop={1}>
              <ProgressLoading fetched={fDefLoading} />
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={12} container className="root-sortable">
          <ReactSortable
            handle=".question-handle"
            list={formDefinitions}
            setList={setFormDefinitions}
            className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 css-cj07d2-MuiGrid-root"
          >
            {formDefinitions.length > 0 && !fDefLoading ? (
              formDefinitions.map((item) => (
                <Grid
                  item
                  container
                  xs={12}
                  key={item.label}
                  sx={{
                    bgcolor: 'whitesmoke',
                    marginTop: 2,
                    paddingY: '24px !important',
                    paddingRight: '24px',
                    borderRadius: 2,
                  }}
                >
                  <Grid item xs={1} className="center-full">
                    {editMode || addMode ? <DragIndicatorIcon className="question-handle" /> : null}
                  </Grid>
                  <Grid item xs={10}>
                    <AQHTextField
                      name={formatLabel(item.label)}
                      control={control}
                      label="Question"
                      margin="normal"
                      maxLength={10}
                      errors={errors}
                      inputProps={{ readOnly: true }}
                    />
                    <AQHTextField
                      sx={{ marginTop: 1 }}
                      name={`${formatLabel(item.label)}_type`}
                      control={control}
                      label="Type"
                      margin="normal"
                      maxLength={10}
                      errors={errors}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={1} className="center-full">
                    {editMode || addMode ? (
                      <IconButton color="primary" onClick={() => removeQuestion(item.label)}>
                        <DeleteIcon />
                      </IconButton>
                    ) : null}
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} mt={3}>
                {!fDefLoading ? (
                  <Typography variant="body1" align="center">
                    {addMode
                      ? 'Form has not been created yet!'
                      : 'No questions available at the moment.'}
                  </Typography>
                ) : null}
              </Grid>
            )}
          </ReactSortable>
        </Grid>
      </Grid>
      <Modal
        isOpen={isShowing}
        handleClose={toggle}
        title="Add Question"
        subtitle=""
        closeButtonText="null"
        icon={<QuestionAnswerIcon />}
      >
        <CreateFormModal
          setData={setFormDefinitions}
          questionData={formDefinitions}
          toggle={toggle}
        />
      </Modal>
      {/* <ModalAlert
        alertConfirmFunction={() => confirmAlert()}
        closeModal={() => cancelAlert()}
        isOpen={alertDialogOpen}
        handleClose={() => cancelAlert()}
        subtitle={
          <>
            Data is being Saved
            <br /> This cannot be edited later. Confirm.
          </>
        }
      /> */}
    </>
  )
}
