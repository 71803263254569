import { useState } from 'react'

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  const toggle = () => {
    setIsShowing(isShowingState=>!isShowingState)
  }

  return {
    isShowing,
    toggle,
  }
}

export default useModal
