import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import moment from 'moment'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography, Button, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'

import AQHButton from '../../../components/ui/AQHButton'
import AQHTextField from '../../../components/ui/AQHInput'
import AQHSelectiveInput from '../../../components/ui/AQHSelectiveInput'

import {
  useAddClientUser,
  useEditClientUser,
  useGetClientUserById,
} from '../../../hooks/data/useClientUsers'
import Constants from '../../../utilities/Constants'
import { configs } from '../../../assets/theme/base/globals'
import { projectState } from '../../../store/projectSlice'
import ModalAlert from '../../../components/ui/modal/ModalAlert'

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('Enter your first name')
    .max(25, "First name must be less than or equal to 25 char's"),
  lastName: yup
    .string()
    .required('Enter your last name')
    .max(25, "Last name must be less than or equal to 25 char's"),
  mobile: yup
    .string()
    .required('Enter your mobile number')
    .matches(/^\d+$/, 'Phone number is not valid')
    .min(10, 'Mobile number must be 10 digits')
    .max(10, 'Mobile number must be 10 digits')
    .typeError('Mobile number is required')
    .matches('^[6-9][0-9]{9}$', 'Please enter a valid mobile number without any country code'),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required('Enter your email')
    .typeError('Email is mandatory'),
  username: yup
    .string()
    .required('Enter your username')
    .min(5, "UserName must be minimum 5 char's")
    .typeError('Username is mandatory'),
  password: yup
    .string()
    .required('Password is mandatory')
    .min(8, "Password must be minimum 8 char's")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!#%*?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    )
    .typeError('Password is mandatory'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Confirm Password does not match')
    .typeError('Confirm Password is required'),
})
const validationSchemaEdit = yup.object().shape({
  firstName: yup
    .string()
    .required('Enter your first name')
    .max(25, "First name must be less than or equal to 25 char's"),
  lastName: yup
    .string()
    .required('Enter your last name')
    .max(25, "Last name must be less than or equal to 25 char's"),
  mobile: yup
    .string()
    .required('Enter your mobile number')
    .matches(/^\d+$/, 'Phone number is not valid')
    .min(10, 'Mobile number must be 10 digits')
    .max(10, 'Mobile number must be 10 digits')
    .typeError('Mobile number is required')
    .matches('^[6-9][0-9]{9}$', 'Please enter a valid mobile number without any country code'),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required('Enter your email')
    .typeError('Email is mandatory'),
  username: yup
    .string()
    .required('Enter your username')
    .min(5, "UserName must be minimum 5 char's")
    .typeError('Username is mandatory'),
})

const defaultValues = {
  firstName: '',
  lastName: '',
  mobile: '',
  email: '',
  username: '',
  password: '',
  inActive: false,
}

function CreateClientUsers({ editId, toggle, refetch }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [dataLoading, setDataLoading] = useState(false)
  const [userDetailsState, setUserDetailsState] = useState(null)
  const notify = (toastMessage) => toast.error(toastMessage)
  const notifySuccess = (toastMessage) => toast.success(toastMessage)
  const { mainAreaIn100vh } = configs

  const { mutateAsync: addClient } = useAddClientUser()
  const { mutateAsync: editClient } = useEditClientUser()

  const [loadedBit, setLoadedBit] = useState(0)

  // const loggedInUser = useSelector(authUser)

  let defaultSchema
  if (editId) {
    defaultSchema = validationSchemaEdit
  } else {
    defaultSchema = validationSchema
  }

  const formOptions = {
    mode: 'onBlur',
    defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(defaultSchema),
  }

  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    reset,
    watch,
    setValue,
  } = useForm(formOptions)

  const watchInactive = watch('inActive')
  // alert on unassigned
  const [alertDialogOpen, setAlertDialogOpen] = useState(false)
  const [userUnassignedConfirmed, setUserUnassignedConfirmed] = useState(false)
  const SubmitButtonRef = useRef()

  const confirmUserUnassigned = () => {
    setUserUnassignedConfirmed(true)
    setAlertDialogOpen(false)
    setTimeout(() => {
      SubmitButtonRef.current.firstElementChild.click()
    }, 1)
  }
  const cancelAlert = () => {
    setValue('inActive', false)
    setAlertDialogOpen(false)
  }

  // ...

  const { organizationId } = useSelector(projectState)
  // edit mode
  // const { id } = useParams()
  const { data: clientUserData, isLoading: clientUserDataLoading } =
    editId !== undefined && editId > 0
      ? useGetClientUserById({ userId: editId })
      : { data: null, isLoading: false }

  useEffect(() => {
    if (clientUserDataLoading === false && clientUserData?.data?.status !== 'success') {
      // display a notice
      if (editId) {
        notify('Error in Store Data Loading')
      }
    } else {
      if (clientUserData?.data?.data?.backTeam === undefined) return

      const cData = clientUserData?.data?.data?.backTeam
      setUserDetailsState(cData)
      // ...
      const editValues = {
        firstName: cData.firstName,
        lastName: cData.lastName,
        mobile: cData.mobile,
        email: cData.email,
        username: cData.userName,
        inActive: cData.inActive,
      }
      // console.log(editValues)
      reset({ ...editValues })
      setLoadedBit((s) => s | 1) // eslint-disable-line no-bitwise
    }
  }, [clientUserData, clientUserDataLoading])

  const onSubmitForm = async (data) => {
    // ...user inActive confirmed
    if (watchInactive && editId) {
      if (!userUnassignedConfirmed) {
        setAlertDialogOpen(true)
        return
      }
    }

    setDataLoading(true)

    const params = {
      organizationId: organizationId ?? 0,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      roleId: 3,
      userName: data.username,
      userPassword: data.password,
      userProjects: [{ ProjectId: id }],
    }
    // console.log(params)
    // edit or save
    if (editId) {
      // edit
      await editClient(
        { ...params, userID: editId, inActive: data.inActive },
        {
          // eslint-disable-next-line no-shadow
          onSuccess: (response) => {
            setDataLoading(false)

            // eslint-disable-next-line no-prototype-builtins
            // console.log(response)
            if (!response) {
              notify('Something went wrong !')
              return
            }
            // eslint-disable-next-line no-prototype-builtins
            if (response.data.hasOwnProperty('error')) {
              notify(response.data.error.message)
            } else {
              notifySuccess(response.data.message)
              // setEditId(null)
              // setClientUsersListMode(true)
              // !!
              toggle()
              refetch()
            }
          },
          onError: (error) => {
            notify(error.getMessage())
          },
        },
      )
    } else {
      // add
      await addClient(params, {
        // eslint-disable-next-line no-shadow
        onSuccess: (response) => {
          // eslint-disable-next-line no-prototype-builtins
          // console.log(response)
          if (!response) {
            notify('Something went wrong !')
            return
          }
          setDataLoading(false)
          // eslint-disable-next-line no-prototype-builtins
          if (response.data.hasOwnProperty('error')) {
            notify(response.data.error.message)
          } else {
            notifySuccess(response.data.message)
            // setClientUsersListMode(true)
            // !!
            toggle()
            refetch()
          }
        },
        onError: (error) => {
          notify(error.getMessage())
        },
      })
    }

    setDataLoading(false)
  }

  // scroll on error
  useEffect(() => {
    if (errors) {
      const errorsValues = Object.values(errors)
      // console.log(errors)
      if (errorsValues.length > 0) {
        const firstErrorElement = document.getElementsByName(errorsValues[0].ref?.name)[0]
        firstErrorElement?.scrollIntoView({ behavior: `smooth`, block: 'center' })
        firstErrorElement?.focus()
        if (Object.keys(dirtyFields).length > 0) {
          notify('Form contains some error or empty fields !')
        }
      }
    }
  }, [errors])

  return (
    <Box className="center-width" minHeight={mainAreaIn100vh}>
      <Grid
        container
        spacing={1.5}
        p={3}
        paddingBottom={0}
        sx={{ borderRadius: '10px', backgroundColor: 'bg.main' }}
        component="form"
        onSubmit={handleSubmit(onSubmitForm)}
      // autoComplete="off"
      >
        {clientUserDataLoading || dataLoading ? (
          <CircularProgress
            color='primary'
            sx={{
              position: 'absolute',
              top: '45%',
              left: '45%',
            }}
          />
        ) : null}
        <Typography variant="h5">Client Users Details</Typography>
        <Grid item xs={12} marginTop={0.75}>
          <AQHTextField
            name="firstName"
            control={control}
            label="First Name"
            margin="normal"
            autoComplete="firstName"
            maxLength={25}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <AQHTextField
            name="lastName"
            control={control}
            label="Last Name"
            maxLength={25}
            margin="normal"
            autoComplete="lastName"
            errors={errors}
          />
        </Grid>

        <Grid item xs={12}>
          <AQHTextField
            name="mobile"
            control={control}
            label="Mobile"
            margin="normal"
            maxLength={10}
            autoComplete="mobile"
            errors={errors}
          />
        </Grid>

        <Grid item xs={12}>
          <AQHTextField
            name="email"
            control={control}
            label="Email"
            margin="normal"
            // autoComplete="username"
            maxLength={75}
            errors={errors}

          />
        </Grid>

        <Grid item xs={12}>
          <AQHTextField
            name="username"
            control={control}
            label="User Name"
            margin="normal"
            // autoComplete="username"
            maxLength={20}
            errors={errors}
            disabled={!!editId}
          />
        </Grid>
        {!editId ? (
          <>
            <Grid item xs={12}>
              <AQHTextField
                name="password"
                control={control}
                label="Password"
                type="password"
                maxLength={20}
                errors={errors}
              // autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <AQHTextField
                name="confirmPassword"
                control={control}
                label="Confirm Password"
                type="password"
                maxLength={20}
                errors={errors}
              // autoComplete="confirm-password"
              />
            </Grid>
          </>
        ) : null}

        {editId ? (
          <Grid item xs={12}>
            <AQHSelectiveInput
              sx={{ marginY: 0 }}
              type="checkbox"
              name="inActive"
              control={control}
              label="InActive"
              //   className="store-unassigned-checkbox"
              errors={errors}
            />
          </Grid>
        ) : null}

        {/* ... */}
        <Grid
          item
          xs={12}
          mx={{ xs: 0.5, sm: 1, md: 3 }}
          ref={SubmitButtonRef}
          // my={1}
          className="action-buttons"
        >
          <AQHButton
            variant="contained"
            size="medium"
            withLoader
            loading={dataLoading}
            disabled={dataLoading || (editId ? loadedBit < 1 : loadedBit < 0)}
            type="submit"
          >
            Save
          </AQHButton>

          <Button
            variant="outlined"
            size="medium"
            onClick={() =>
              toggle()
            }
            sx={{ margin: '20px', fontWeight: '500', fontSize: '12px' }}
            disabled={dataLoading}
          >
            Cancel
          </Button>
        </Grid>
        <ModalAlert
          alertConfirmFunction={() => confirmUserUnassigned()}
          closeModal={() => cancelAlert()}
          isOpen={alertDialogOpen}
          handleClose={() => cancelAlert()}
          subtitle={
            <>
              You have marked the user as InActive.
              <br /> This cannot be undone. Confirm.
            </>
          }
        />
      </Grid>
    </Box>
  )
}

CreateClientUsers.propTypes = {
  editId: PropTypes.number,
  toggle: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,

}
CreateClientUsers.defaultProps = {
  editId: null,
}
export default CreateClientUsers
