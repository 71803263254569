import React from 'react'
import { Button, Avatar } from '@mui/material'
import PropTypes from 'prop-types'
import {
  DialogStyled,
  DialogActionsStyled,
  DialogContentStyled,
  DialogContentTextStyled,
  DialogTitleStyled,
} from './ModalStyle'

function Modal({ isOpen, handleClose, title, subtitle, closeButtonText, children, maxWidth, icon, ...rest }) {
  return (
    <DialogStyled
      // fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      {...rest}
    >
      <DialogTitleStyled sx={{ bgcolor: 'primary.main' }} id="max-width-dialog-title">{icon ? <><Avatar className='modal-type-1' sx={{ bgcolor: 'primary.main', }}>{icon}</Avatar>{title}</> : title}</DialogTitleStyled>
      <DialogContentStyled>
        {
          subtitle !== 'null' ?
            <DialogContentTextStyled>{subtitle}</DialogContentTextStyled>
            : null
        }
        {children}
      </DialogContentStyled>
      <DialogActionsStyled>
        {closeButtonText !== 'null' ? (
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{ margin: '20px', fontWeight: '500', fontSize: '12px' }}
          >
            {closeButtonText}
          </Button>
        ) : null}
      </DialogActionsStyled>
    </DialogStyled>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  closeButtonText: PropTypes.string,
  children: PropTypes.element.isRequired,
  maxWidth: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
  icon: PropTypes.any,
}

Modal.defaultProps = {
  subtitle: '',
  closeButtonText: 'Close',
  maxWidth: 'md',
  icon: null,
}
export default Modal
