import { useQuery, useMutation } from 'react-query'
import {
  getFormDataById,
  exportFormDataExcel,
  exportFormDataPDF,
} from './endPoints'



const useGetFormDataByID = ({ formDataId }) =>
  useQuery([`getFormDataByID-${formDataId}`], () => getFormDataById({ formDataId }), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled:false
  })

export const useExportFormDataExcel = ({ projectId }) =>exportFormDataExcel({ projectId })
export const useExportFormDataPDF = ({ projectId, withImage, startDate, endDate }) =>
  exportFormDataPDF({ projectId, withImage, startDate, endDate })

export default useGetFormDataByID
