import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@mui/material'

import AQHButton from '../../components/ui/AQHButton'
import AQHInput from '../../components/ui/AQHInput'
import AQHSelectiveInput from '../../components/ui/AQHSelectiveInput'

const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required.'),
    phone: yup.number().required('Contact Number is required.').typeError('Contact Number is required.'),
    email: yup.string().email('Email must be a valid email.').required('Email ID is required.')
})
const validationSchemaQ = yup.object().shape({
    answer: yup.string().required('Please answer the question to proceed.')
})

const defaultValues = {
    name: '',
    phone: '',
    email: ''
}

const formOptions = {
    mode: 'onBlur',
    defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
}

const testData = [
    {
        q: 'What was the first video game in the Batman "Arkham" series?',
        o: ['Arkham Knight', 'Arkham Asylum', 'Arkham Origins', 'Arkham City'],
        a: 'Arkham Asylum'
    },
    {
        q: 'How many manned moon landings have there been?',
        o: ['1', '6', '3', '7'],
        a: '6'
    },
    {
        q: 'Which nation claims ownership of Antarctica?',
        o: ['United States of America', 'No one, but there are claims.', 'United Nations', 'Australia'],
        a: 'No one, but there are claims.'
    },
    {
        q: 'Which of the following carbonated soft drinks were introduced first?',
        o: ['Coca-Cola', 'Dr. Pepper', 'Sprite', 'Mountain Dew'],
        a: 'Dr. Pepper'
    },
]

function TestQuiz() {
    const [infoReceived, setInfoReceived] = useState(false)
    const [index, setIndex] = useState(0)
    const [answered, setAnswered] = useState(false)
    // const [userAnswers, setUserAnswers] = useState([])
    const [correctAnswer, setCorrectAnswer] = useState(0)
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm(formOptions)

    const {
        handleSubmit: handleSubmitQ,
        control: controlQ,
        formState: { errors: errorsQ },
    } = useForm({
        mode: 'onBlur',
        defaultValues: { answer: '' },
        shouldUnregister: false,
        resolver: yupResolver(validationSchemaQ)
    })

    const onSubmitInfo = (data) => {
        console.log(data)
        setInfoReceived(true)
    }
    const onSubmitQuestion = (data) => {
        console.log(data)
        // setUserAnswers(s => [...s, data.answer])
        if (data.answer === testData[index].a) {
            console.log('correct')
            setCorrectAnswer(s => s + 1)
        }
        // setInfoReceived(true)
        setIndex(s => {
            if (s < testData.length - 1) {
                return s + 1
            }

            setAnswered(true)
            setInfoReceived(false)
            // console.log(userAnswers)
            // console.log({ correctAnswer });
            return 0

        })
    }

    if (answered) {
        return <Box minHeight='100vh' className='center-full'>
            {
                correctAnswer === 4 ? 'Congratulations, You Win!' : `Better Luck Next Time ( Correct Answer : ${correctAnswer}/${testData.length}  ) `
            }
        </Box>
    }


    return (
        <Box minHeight='100vh' className='center-full'>
            {
                !infoReceived ?
                    <Grid component='form' onSubmit={handleSubmit(onSubmitInfo)} autoComplete="off" container width={500} spacing={1}>
                        Participate and win...
                        <Grid item xs={12}>
                            <AQHInput
                                name="name"
                                control={control}
                                label="Name"
                                margin="dense"
                                errors={errors}
                                maxLength={25}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AQHInput
                                name="phone"
                                control={control}
                                label="Contact Number"
                                type='number'
                                margin="dense"
                                errors={errors}
                                maxLength={10}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AQHInput
                                name="email"
                                control={control}
                                label="Email"
                                type='email'
                                margin="dense"
                                errors={errors}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} className="action-buttons" marginTop={1}>
                            <AQHButton
                                variant="contained"
                                size="medium"
                                type="submit"
                            // withLoader
                            // loading={dataLoading}
                            // disabled={dataLoading}
                            >
                                Proceed
                            </AQHButton>

                        </Grid>
                    </Grid> :
                    <Grid component='form' onSubmit={handleSubmitQ(onSubmitQuestion)} autoComplete="off" container width={500} spacing={1}>
                        {testData[index].q}
                        <Grid item xs={12}>
                            <AQHSelectiveInput
                                type="radio"
                                options={testData[index].o.map((e) => ({ value: e, label: e }))}
                                control={controlQ}
                                name="answer"
                                label=" "
                                errors={errorsQ}
                                row={false}
                            />
                        </Grid>

                        <Grid item xs={12} className="action-buttons" marginTop={1}>
                            <AQHButton
                                variant="contained"
                                size="medium"
                                type="submit"
                            // withLoader
                            // loading={dataLoading}
                            // disabled={dataLoading}
                            >
                                Proceed
                            </AQHButton>

                        </Grid>
                    </Grid>
            }

        </Box>
    )
}

export default TestQuiz
